import React from 'react';


const Card = (props) => {
  return (

    <div style={{marginTop:'10px', marginLeft:'0px', marginBottom:'10px'}} className="ui unstackable items">
      <div className="item">
        <div>
          <img className="projectImages" style={{borderRadius: '10px', border: '1px solid white', height:'100px', width:'100px'}} alt='propImage' src={props.pic} />
        </div>
        <div style={{paddingLeft:'.7em'}} className="content">
          <a href={props.url} className="CColor">{props.name} <p style={{display:'inline', fontSize:'.6em', color:'#C6D1DB'}}>- {props.maintenance}</p> </a>
          <div style={{marginBottom:'3px'}} className="meta">
            <span className="CColor">{props.description}</span>
          </div>
          <div style={{margin:'0px' }}  className="extra">
            <a style={{margin:'2px', fontSize:'.65em', backgroundColor:'#AEB6BF'}} className="ui label">
              {props.tag1}
            </a>
            <a style={{margin:'2px', fontSize:'.65em', backgroundColor:'#AEB6BF'}} className="ui label">
              {props.tag2}
            </a>

            <a style={{margin:'2px', fontSize:'.65em', backgroundColor:'#AEB6BF'}} className="ui label">
              {props.tag3}
            </a>

            <a style={{margin:'2px', fontSize:'.65em', backgroundColor:'#AEB6BF'}} className="ui label">
              {props.tag4}
            </a>

          </div>
        </div>
      </div>

    </div>

  );
};


export default Card;
