import './style.css';
import React from 'react';
import image from './static/eli.jpeg';

class LeftContainer extends React.Component {

  render() {
    return (
      <div className="innerDiv">

              <img style={{maxHeight:'190px', maxWidth:'190px', border:'1px solid #566573', borderRadius:'50%'}}   src={image} />


              <h2 style={{marginBottom:'0px', paddingBottom:'.1em', paddingTop:'.5em'}} >Eli Silvestre</h2>


              <h4 style={{marginTop:'0px', paddingBottom:'.5em'}}>NYC</h4>


                <div>
                <a href="https://www.linkedin.com/in/eliazarsilvestre/">
                  <i className="large circular inverted linkedin icon"> </i>
                </a>

                <a href="https://github.com/luigi0229">
                  <i className="large circular inverted github icon"></i>
                </a>

                <a href="mailto:luigi0229@gmail.com">
                  <i className="large circular inverted mail icon"></i>
                </a>
                </div>

      </div>
    );
  }
};

export default LeftContainer;
