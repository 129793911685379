import './style.css';
import React from 'react';
import Card from './Card';
import cd from './static/cd.png';
import audit from './static/audit.png';
import lineup from './static/lineup.png';
import trill from './static/trillLogo.png';
import eisentask from './static/eisentask.png';
import toorit from './static/toorit.png';


const RightContainer = () => {

  return (

        <div>

        <div className="ui container">
          <h2>About me</h2>
            <p>
            I'm a <strike className="fade">Software</strike> Cloud Engineer who's passionate about programming and tinkering with new technology.
            I enjoy travel, video games, and to dabble in <strike className="fade">writting</strike> rushing code for random ideas using new tools and frameworks.
            </p>
          </div>
          <br/>

          <div className="ui container">
          <h2>Projects</h2>
            <Card url="https://toorit.io" pic={toorit} tag1="React Native" tag2="Stripe" tag3="Cloud Functions" tag4="Redux" name="Toorit" maintenance="2020" description = "A booking platform for tour operators."/>
            < hr class="fancy-line"></hr>
            <Card url="https://apps.apple.com/app/id1502486067" pic={eisentask} tag1="React Native" tag2="Firebase" tag3="Cloud Functions" tag4="Redux" name="EisenTask" maintenance="2020" description = "Free To-Do List app that combines bullet journaling with the Eisenhower Matrix to better prioritize tasks. Built using React Native, for iOS and Android."/>
            < hr class="fancy-line"></hr>
            <Card url="https://trillnyc.com/" pic={trill} tag1="React Native" tag2="MongoDB" tag3="Node.js" tag4="Redux" name="Trill NYC" maintenance="2019" description = "Full-Stack Developer at Trill NYC before and during it's Alpha Launch."/>
              <hr class="fancy-line"></hr>
            <Card url="http://audit.elisilvestre.com" pic={audit} tag1="Pandas" tag2="Python" tag3="Powershell" tag4="S3" name="NTFS Audit" maintenance="2019"  description = "NTFS Permissions report."/>
              <hr class="fancy-line"></hr>
            <Card url="https://www.lineupapplication.com/" pic={lineup} tag1="React Native" tag2="JavaScript" tag3="MYSQL" tag4="Wix"  name="Lineup App" maintenance="2019" description = "Collaborator to Lineup App."/>
              <hr class="fancy-line"></hr>
            <Card url="http://capstone.elisilvestre.com" pic={cd} tag1="Django" tag2="WireShark" tag3="D3.js" tag4="Python" name=" Hunter Hottest Sites " maintenance="2016"  description = "Undergrad capstone project. A visual representation of the WiFi network traffic at Hunter College." />





          </div>
          <br/>
          <div className="ui container">
            <h2> Skills</h2>
              <div class="ui list">
                <div class="item" style={{fontSize:'.9em'}}>
                  React Native
                </div>
                <hr class="fancy-line"></hr>
                <div class="item" style={{fontSize:'.9em'}}>
                  Python
                </div>
                <hr class="fancy-line"></hr>
                <div class="item" style={{fontSize:'.9em'}}>
                  Javascript
                </div>
                <hr class="fancy-line"></hr>
                <div class="item" style={{fontSize:'.9em'}}>
                  Infrastructure as Code
                </div>
                <hr class="fancy-line"></hr>
                <div class="item" style={{fontSize:'.9em'}}>
                  AWS/GCP/Azure (In that order)
                </div>
                <hr class="fancy-line"></hr>
                <div class="item" style={{fontSize:'.9em'}}>
                  Docker/Kubernetes
                </div>
                <hr class="fancy-line"></hr>
                <div class="item" style={{fontSize:'.9em'}}>
                  NoSQL
                </div>
                <hr class="fancy-line"></hr>
                <div class="item" style={{fontSize:'.9em'}}>
                  Serverless
                </div>
                <hr class="fancy-line"></hr>
                <div class="item" style={{fontSize:'.9em'}}>
                  C++
                </div>
                <hr class="fancy-line"></hr>
              </div>
            </div>
          <br/>

          <div className="ui container">
            <h2>Writting</h2>
              <div className="item">
                <i style={{paddingBottom:'30px'}} class="large book icon"></i>
              </div>
              <p className="writtingText">Still working on it...</p>
            </div>
          <br/>

          <div className="ui container">
            <h2>Hobbies</h2>
              <i style={{marginRight:'16px', marginTop:'10px', color:'#AEB6BF'}} className="big inverted table tennis icon"></i>
              <i style={{marginRight:'16px', marginTop:'10px', color:'#AEB6BF'}} className="big inverted bicycle icon"></i>
              <i style={{marginRight:'16px', marginTop:'10px', color:'#AEB6BF'}} className="big inverted gamepad icon"></i>
              <i style={{marginRight:'16px', marginTop:'10px', color:'#AEB6BF'}} className="big inverted file code outline icon"></i>
              <i style={{marginRight:'16px', marginTop:'10px', color:'#AEB6BF'}} className="big inverted tv icon"></i>
              <i style={{marginRight:'16px', marginTop:'10px', color:'#AEB6BF'}} className="big inverted coffee icon"></i>
              <i style={{marginRight:'16px', marginTop:'10px', color:'#AEB6BF'}} className="big inverted plane icon"></i>
              <i style={{marginRight:'16px', marginTop:'10px', color:'#AEB6BF'}} className="big inverted bed icon"></i>

            </div>
          <br/>
        </div>
  );
};

export default RightContainer;
