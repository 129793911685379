//import statemens
import './style.css';
import React from 'react';
import ReactDOM from 'react-dom';
import RightContainer from './RightContainer';
import LeftContainer from './LeftContainer';



class App extends React.Component {
  state = {test: ''}

  componentDidMount() {
    let $ = window.$
    ('.leftDiv').transition({
      animation: 'pulse'
    })
    $ = window.$
    ('.rightDiv').transition({
      animation: 'pulse'
    })

  }

  render () {

  return (
  <div className="mainDiv" >
        <div className="leftDiv">
          <div className="ui container">
                <LeftContainer />
          </div>
        </div>

        <div className="rightDiv">
            <RightContainer />
        </div>

    </div>
  );
  }
}

//Display it on the index.html
ReactDOM.render(
  <App />,
  document.querySelector("#root")
);
